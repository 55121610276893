import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Textarea,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Text,
  VStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { createEncounter, deleteEncounter, getEncounters, updateEncounter } from '../services/api';
import Sidebar from '../components/common/Sidebar';
import Header from '../components/common/Header';

const EncountersPage = () => {
  const { appointmentId } = useParams();
  const [encounters, setEncounters] = useState();
  const [selectedEncounter, setSelectedEncounter] = useState(null);
  const [newEncounter, setNewEncounter] = useState({ appointmentId: appointmentId, log: '' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [title, setTitle] = useState('');

  const fetchEncounters = async () => {
    try {
      const response = await getEncounters(appointmentId);
      setEncounters(response?.data);
    } catch (error) {
      toast({
        title: 'Error fetching encounters.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setTitle('Encounter file: ' + appointmentId);
    fetchEncounters();
  }, [appointmentId]);

  const handleAddEncounter = async () => {
    try {
      const response = await createEncounter(newEncounter);
      // setEncounters([...encounters, response?.data]);
      fetchEncounters();

      setNewEncounter({ appointmentId: appointmentId, log: '' });
      onClose();
      toast({
        title: 'Encounter added.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error adding encounter.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditEncounter = async () => {
    if (selectedEncounter && selectedEncounter.id) {
      try {
        const response = await updateEncounter(selectedEncounter.id, selectedEncounter);
        fetchEncounters();
        setSelectedEncounter(null);
        onClose();
        toast({
          title: 'Encounter updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error updating encounter.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'No encounter selected.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteEncounter = async (id) => {
    try {
      await deleteEncounter(id);
      fetchEncounters();

      toast({
        title: 'Encounter deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting encounter.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openEditModal = (encounter) => {
    setSelectedEncounter(encounter);
    onOpen();
  };

  const handleToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Flex>
      <Sidebar isOpen={isSidebarOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isSidebarOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isSidebarOpen} onToggle={handleToggle} title={title} />
        <Box p={4}>
          {!encounters || encounters.length === 0 ? (
            <Flex align="center" justify="center" height="100vh">
              <VStack>
                <Text>No Encounters</Text>
                <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme="teal">
                  Add Encounter
                </Button>
              </VStack>
            </Flex>
          ) : (
            <>
              <Table size={'sm'} colorScheme='teal' variant="simple">
                <Divider h={10} />
                <Button size={'sm'} onClick={onOpen} leftIcon={<AddIcon />}  colorScheme="teal">
                  Add Encounter
                </Button>
                <Thead>
                  <Tr>
                    <Th>Log</Th>
                    <Th>DateTime</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {encounters.map(encounter => (
                    <Tr key={encounter.id}>
                      <Td>{encounter.log}</Td>
                      <Td>{encounter.createdAt}</Td>
                      <Td>
                        <IconButton
                          aria-label="Edit"
                          colorScheme='teal'
                          size={'sm'}
                          icon={<EditIcon />}
                          onClick={() => openEditModal(encounter)}
                          mr={2}
                        />
                        <IconButton
                          aria-label="Delete"
                          colorScheme='red'
                          size={'sm'}
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteEncounter(encounter.id)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </>
          )}
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedEncounter ? 'Edit Encounter' : 'Add Encounter'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                placeholder="Log"
                value={selectedEncounter ? selectedEncounter.log : newEncounter.log}
                onChange={e =>
                  selectedEncounter
                    ? setSelectedEncounter({ ...selectedEncounter, log: e.target.value })
                    : setNewEncounter({ ...newEncounter, log: e.target.value })
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="teal"
                onClick={selectedEncounter ? handleEditEncounter : handleAddEncounter}
                ml={3}
              >
                {selectedEncounter ? 'Update' : 'Add'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default EncountersPage;

// src/pages/ThankYouPage.js
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Button, Spinner, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../services/firebase';
import { verifyTransaction } from '../../services/api'; // Import your API method
import { FaHome } from 'react-icons/fa';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [searchParams] = useSearchParams(); // Get query parameters from URL
  const status = searchParams.get('status');
  const tx_ref = searchParams.get('tx_ref');
  const transaction_id = searchParams.get('transaction_id');
  const [message,setMessage]=useState();
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        setLoading(true);
        
        const result = await verifyTransaction(tx_ref, transaction_id, status);
        if (status === 'success') {
          setMessage("TTransaction completed successfully");

        }
        else  if (status === 'cancelled'){
          setMessage("You have cancelled the transaction")
        }else{
          setMessage("There was an issue with your transaction. Please try again or contact support.")

        }

          setTransactionData(result.data.data);
       
      } catch (error) {
        console.error('Error verifying transaction:', error);
        setTransactionData(null);
      } finally {
        setLoading(false);
      }
    };

    if (tx_ref && status) {
      fetchTransactionStatus();
    } else {
      setTransactionData(null);
      setLoading(false);
    }
  }, [tx_ref, transaction_id, status]);

  return (
    <Flex align="center" justify="center" direction="column" p={8} bg="teal.50" minH="100vh">
      <Box
        w="full"
        maxW="lg"
        p={8}
        borderWidth={1}
        borderRadius="lg"
        overflow="hidden"
        bg="white"
        boxShadow="md"
        textAlign="center"
      >
        {loading ? (
          <Spinner size="xl" color="teal.500" />
        ) : transactionData ? (
          <>
            <Box mb={4} p={4} borderRadius="md" bg="teal.100" color="teal.800" textAlign="center">
              <Heading size="lg">Payment Successful!</Heading>
              <Text fontSize="md" mt={2}>Thank you for your payment.</Text>
            </Box>
            <VStack spacing={4} align="start" mb={8} p={4} borderWidth={1} borderRadius="md" borderColor="teal.200">
              <Text fontSize="sm"><strong>Transaction ID:</strong> {transactionData.id}</Text>
              <Text fontSize="sm"><strong>Reference:</strong> {transactionData.tx_ref}</Text>
              <Text fontSize="sm"><strong>Amount:</strong> {transactionData.amount} {transactionData.currency}</Text>
              <Text fontSize="sm"><strong>Charged Amount:</strong> {transactionData.charged_amount}</Text>
              <Text fontSize="sm"><strong>App Fee:</strong> {transactionData.app_fee}</Text>
              <Text fontSize="sm"><strong>Merchant Fee:</strong> {transactionData.merchant_fee}</Text>
              <Text fontSize="sm"><strong>Processor Response:</strong> {transactionData.processor_response}</Text>
              <Text fontSize="sm"><strong>Auth Model:</strong> {transactionData.auth_model}</Text>
              <Text fontSize="sm"><strong>Narration:</strong> {transactionData.narration}</Text>
              <Text fontSize="sm"><strong>Payment Type:</strong> {transactionData.payment_type}</Text>
              <Text fontSize="sm"><strong>Created At:</strong> {new Date(transactionData.created_at).toLocaleString()}</Text>
              <Text fontSize="sm"><strong>Amount Settled:</strong> {transactionData.amount_settled}</Text>
              <Text fontSize="sm"><strong>Customer Name:</strong> {transactionData.customer.name}</Text>
              <Text fontSize="sm"><strong>Customer Email:</strong> {transactionData.customer.email}</Text>
            </VStack>
            <Button
              colorScheme="teal"
              onClick={() => navigate(user ? '/' : '/sign-up')}
              size="lg"
              variant="solid"
              borderRadius="md"
            >
              {user ? 'Go Home' : 'Sign Up'}
            </Button>
          </>
        ) : (
          <>         <Text fontSize="lg" mb={8} color="red.500">
          {message}
          </Text>
          <Button colorScheme="teal" leftIcon={<FaHome />} onClick={() => navigate("/")} variant="outline">
                Home
              </Button>
          </>
 
        )}
      </Box>
    </Flex>
  );
};

export default ThankYouPage;

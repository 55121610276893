import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { FaRegCalendarCheck } from 'react-icons/fa';

export const Logo = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" py={4} px={2}>
      <Image src="/assets/images/Pine.png" alt="PinePlanner Logo" boxSize="200px" mr={2} />
    </Box>
  );
};


export const SecondaryLogo = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" py={4} px={2}>
      <Image src="/assets/images/secondaryLogo.png" alt="PinePlanner Logo" boxSize="300px" mr={2} />
    </Box>
  );
};



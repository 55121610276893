import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  useToast,
  Spinner,
  StackDivider,
} from '@chakra-ui/react';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import { getNotifications } from '../services/api';

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await getNotifications();
        setNotifications(response.data);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to fetch notifications',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [toast]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Flex>
    <Sidebar isOpen={isOpen} onToggle={handleToggle} />
        <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
          <Header isOpen={isOpen} onToggle={handleToggle} title={"Notifications"} />

      <Box flex="1" ml={{ base: "0", md: "250px" }} transition="margin-left 0.3s">
        <Box p={4} mt="80px" mx="auto" maxW="900px">
          {loading ? (
            <Spinner size="xl" color="teal.500" />
          ) : (
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              {notifications && notifications.map((notification) => (
                <Box
                  key={notification.id}
                  p={4}
                  bg="gray.50"
                  borderRadius="md"
                  boxShadow="sm"
                  _hover={{ bg: "gray.100" }}
                >
                  <Text fontWeight="bold" color="teal.600">{notification.title}</Text>
                  <Text color="gray.700">{notification.body}</Text>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    {new Date(notification.createdAt).toLocaleString()}
                  </Text>
                </Box>
              ))}
            </VStack>
          )}
        </Box>
      </Box>
      </Box>
    </Flex>
  );
};

export default NotificationPage;

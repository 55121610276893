import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { createAppointment } from '../../services/api';

const BookAppointmentModal = ({ isOpen, onClose, professionalId, selectedService }) => {
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setLoading(true);
    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);

    try {
      const resp = await createAppointment({
        professionalId,
        startTime: startDateTime.toISOString(),
        endTime: endDateTime.toISOString(),
        description: description,
        email: email,
        phoneNumber: phoneNumber,
        amount: selectedService.price.toString(),
        name: name,
        service: selectedService.name,
        currency:"usd"
      });

      if (resp.data.status === "success") {
        toast({
          title: 'Appointment booked.',
          description: 'Your appointment has been successfully booked.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        window.location.href = resp.data.data.link; // Redirect to the payment link
      } else {
        toast({
          title: 'Error booking appointment.',
          description: resp.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error booking appointment.',
        description: 'There was an error booking your appointment. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Book an Appointment for {selectedService?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="service" mb={4}>
            <FormLabel>Service</FormLabel>
            <Input type="text" value={selectedService?.name} readOnly />
          </FormControl>
          <FormControl id="name" mb={4}>
            <FormLabel>Name</FormLabel>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl id="phoneNumber" mb={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </FormControl>
          <FormControl id="date" mb={4}>
            <FormLabel>Date</FormLabel>
            <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </FormControl>
          <FormControl id="startTime" mb={4}>
            <FormLabel>Start Time</FormLabel>
            <Input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
          </FormControl>
          <FormControl id="endTime" mb={4}>
            <FormLabel>End Time</FormLabel>
            <Input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
          </FormControl>
          <FormControl id="description" mb={4}>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
          <Button colorScheme="teal" isLoading={loading} onClick={handleSubmit}>Book</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookAppointmentModal;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  SimpleGrid,
  HStack,
  VStack,
  Tag,
  useColorModeValue,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { FaCalendarAlt, FaBell, FaClipboardList, FaEdit, FaTrash, FaReceipt, FaRemoveFormat, FaPeopleArrows, FaTrafficLight, FaMouse } from 'react-icons/fa';
import {
  getUserProfile,
  getUserAppointments,
  getUserNotifications,
  getUserActivities,
  getProfessionalSchedules,
  getStatistics,
  updateSchedule,
  deleteSchedule,
  updateAppointment,
  deleteAppointment,
  getCustomAnalytics,
  getReferalCount,
} from '../services/api';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import Loader from '../components/common/Loader';
import UserProfileBox from '../components/User/UserProfileBox';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaMoneyBill1Wave, FaScaleUnbalancedFlip } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { getAnalytics } from 'firebase/analytics';


const formatCurrency = (value, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value);
};


const Dashboard = ({currency}) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [referalCount, setReferalCount] = useState([]);

  const [activities, setActivities] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [appointments, setAppointments] = useState({
    scheduled: [],
    pending: [],
    missed: [],
    cancelled: []
  });
  const [currentAppointment, setCurrentAppointment] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user] = useAuthState(auth);
  const toast = useToast();
const navigate=useNavigate();
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    startTime: yup.date().required(),
    endTime: yup.date().required(),
    status: yup.string().required(),
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const userProfile = await getUserProfile();
      setProfile(userProfile?.data || {});

      if (userProfile?.data) {
        const stats = await getStatistics(userProfile?.data.uid);
        setStatistics(stats?.data || {});
        const anytics = await getCustomAnalytics();
        setAnalytics(anytics?.data || {});
        const refs = await getReferalCount(userProfile?.data.handle);
        setReferalCount(refs?.data || {});
      
      }

      const userNotifications = await getUserNotifications();
      setNotifications(userNotifications?.data || []);

   

      const professionalSchedules = await getProfessionalSchedules();
      setSchedules(professionalSchedules?.data || []);

      const userAppointments = await getProfessionalSchedules();
      const categorizedAppointments = userAppointments?.data.reduce((acc, appointment) => {
        if (appointment.status === 'scheduled') {
          acc.scheduled.push(appointment);
        } else if (appointment.status === 'pending') {
          acc.pending.push(appointment);
        } else if (appointment.status === 'missed') {
          acc.missed.push(appointment);
        } else if (appointment.status === 'cancelled') {
          acc.cancelled.push(appointment);
        }
        return acc;
      }, { scheduled: [], pending: [], missed: [], cancelled: [] });

      setAppointments(categorizedAppointments);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateSchedule = (appointment) => {
    setCurrentAppointment(appointment);
    reset({
      name: appointment.name,
      description: appointment.description,
      startTime: appointment.startTime.slice(0, 16), // Format to "yyyy-MM-ddTHH:mm"
      endTime: appointment.endTime.slice(0, 16), // Format to "yyyy-MM-ddTHH:mm"
      status: appointment.status,
    });
    onOpen();
  };

  const onSubmit = async (data) => {
    try {
      await updateAppointment(currentAppointment.id, data);
      toast({
        title: "Schedule updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchData();
      onClose();
    } catch (error) {
      toast({
        title: "Failed to update schedule.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await deleteAppointment(scheduleId);
      toast({
        title: "Schedule deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchData();
    } catch (error) {
      toast({
        title: "Failed to delete schedule.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Flex>
      <Box flex="1" transition="margin-left 0.3s">

        <Box p={4} mt="100px">
          <Box>
          {profile && (
              <UserProfileBox profile={profile} user={user} />
            )}

          </Box>
       <Divider h={10}/>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>

          <Box p={6} borderRadius="md" boxShadow="md" bg="purple.100">
              <Heading as="h3" size="sm" mb={4} color={'purple.700'}>
                <Icon as={FaPeopleArrows} mr={2} />
                Referals: {referalCount?.data||0}
              </Heading>
              <Heading as="h3" size="sm" mb={4} color={'purple.700'}>
                <Icon as={FaTrafficLight} mr={2} />
                Page Views: {analytics?.pageViews}
              </Heading>
              <Heading as="h3" size="sm" mb={4} color={'purple.700'}>
                <Icon as={FaMouse} mr={2} />
                Clicks: {analytics?.clicks}
              </Heading>
            </Box>

            {/* Statistics */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="teal.100">
              <Heading as="h3" size="md" mb={4} color={'teal.700'}>
                <Icon as={FaCalendarAlt} mr={2} />
                Appointments
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'teal.700'}>
                {statistics?.totalAppointments}
              </Heading>
            </Box>

            <Box p={6} borderRadius="md" boxShadow="md" bg="orange.100">
              <Heading as="h3" size="md" mb={4} color={'orange.700'}>
                <Icon as={FaCalendarAlt} mr={2} />
                Ongoing
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'orange.700'}>
                {statistics?.startedCount}
              </Heading>
            </Box>

            <Box p={6} borderRadius="md" boxShadow="md" bg="gray.100">
              <Heading as="h3" size="md" mb={4} color={'gray.700'}>
                <Icon as={FaCalendarAlt} mr={2} />
                Unconfirmed Revenue
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'gray.700'}>
                {formatCurrency(statistics?.totalRevenueOtherStatuses || 0,currency)}

              </Heading>
            </Box>
            {/* Statistics */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="green.100">
              <Heading as="h3" size="md" mb={4} color={'green.700'}>
                <Icon as={FaReceipt} mr={2} />
                Earning
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'green.700'}>
              {formatCurrency(statistics?.completedAppointments || 0,currency)}
              </Heading>
            </Box>
            <Box p={6} borderRadius="md" boxShadow="md" bg="blue.100">
              <Heading as="h3" size="md" mb={4} color={'blue.700'}>
                <Icon as={FaMoneyBill1Wave} mr={2} />
                Comfirmed payment
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'blue.700'}>
              {formatCurrency(statistics?.totalRevenuePaid || 0,currency)}
              </Heading>
            </Box>



            <Box p={6} borderRadius="md" boxShadow="md" bg="yellow.100">
              <Heading as="h3" size="md" mb={4} color={'yellow.700'}>
                <Icon as={FaCalendarAlt} mr={2} />
                Missed Appointments
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'yellow.700'}>
              {formatCurrency(statistics?.missedAppointments || 0,currency)}
              </Heading>
            </Box>
            <Box p={6} borderRadius="md" boxShadow="md" bg="red.100">
              <Heading as="h3" size="md" mb={4} color={'red.700'}>
                <Icon as={FaRemoveFormat} mr={2} />
                Cancelled Appointments
              </Heading>
              <Heading as="h3" size="md" mb={4} color={'red.700'}>
                {formatCurrency(statistics?.cancelledAppointments || 0,currency)}

              </Heading>
            </Box>

            {/* Scheduled Appointments */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="teal.100">
              <Heading as="h3" size="md" mb={4} color={'teal.700'}>
                <Icon as={FaCalendarAlt} mr={2} />
                Scheduled Appointments
              </Heading>
              {appointments.scheduled.length === 0 ? (
                <Text>No scheduled appointments.</Text>
              ) : (
                <VStack align="start" spacing={3}>
                  {appointments.scheduled.map((appointment) => (
                    <Box key={appointment.id} w="full" p={4} borderRadius="md" bg="white">
                      <HStack justify="space-between">
                        <VStack align="start">
                          <Tag><Text>{appointment.name}</Text></Tag>
                          <Tag colorScheme='green'><Text>{appointment.service}</Text></Tag>
                          <Button colorScheme='teal' size={'sm'} onClick={()=>navigate(`/encounters/${appointment.id}`)}>Encounters</Button>

                          <Text>{appointment.description}</Text>
                          <Tag colorScheme="teal" variant={'outline'}>
                            {new Date(appointment.startTime).toLocaleDateString()} {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                          </Tag>

                        </VStack>
                        <HStack spacing={2}>
                          <Button size="sm" colorScheme="yellow" onClick={() => handleUpdateSchedule(appointment)}>
                            <Icon as={FaEdit} />
                          </Button>
                          {/* <Button size="sm" colorScheme="red" onClick={() => handleDeleteSchedule(appointment.id)}>
                            <Icon as={FaTrash} />
                          </Button> */}
                        </HStack>

                      </HStack>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>

            {/* Missed Appointments */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="yellow.100">
              <Heading as="h3" size="md" mb={4} color={'yellow.700'}>
                <Icon as={FaBell} mr={2} />
                Missed Appointments
              </Heading>
              {appointments.missed.length === 0 ? (
                <Text>No missed appointments.</Text>
              ) : (
                <VStack align="start" spacing={3}>
                  {appointments.missed.map((appointment) => (
                    <Box key={appointment.id} w="full" p={4} borderRadius="md" bg="white">
                      <HStack justify="space-between">
                        <VStack align="start">
                          <Tag><Text>{appointment.name}</Text></Tag>
                          <Tag colorScheme='green'><Text>{appointment.service}</Text></Tag>
                          <Button colorScheme='teal' size={'sm'} onClick={()=>navigate(`/encounters/${appointment.id}`)}>Encounters</Button>

                          <Text>{appointment.description}</Text>
                          <Tag colorScheme="teal" variant={'outline'}>
                            {new Date(appointment.startTime).toLocaleDateString()} {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                          </Tag>
                        </VStack>
                        <HStack spacing={2}>
                          <Button size="sm" colorScheme="yellow" onClick={() => handleUpdateSchedule(appointment)}>
                            <Icon as={FaEdit} />
                          </Button>
                          <Button size="sm" colorScheme="red" onClick={() => handleDeleteSchedule(appointment.id)}>
                            <Icon as={FaTrash} />
                          </Button>
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>

            {/* Pending Appointments */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="gray.200">
              <Heading as="h3" size="md" mb={4} color={'gray.700'}>
                <Icon as={FaClipboardList} mr={2} />
                Pending Appointments
              </Heading>
              {appointments.pending.length === 0 ? (
                <Text>No pending appointments.</Text>
              ) : (
                <VStack align="start" spacing={3}>
                  {appointments.pending.map((appointment) => (
                    <Box key={appointment.id} w="full" p={4} borderRadius="md" bg="white">
                      <HStack justify="space-between">
                        <VStack align="start">
                          <Tag><Text>{appointment.name}</Text></Tag>
                          <Tag colorScheme='green'><Text>{appointment.service}</Text></Tag>
                          <Button colorScheme='teal' size={'sm'} onClick={()=>navigate(`/encounters/${appointment.id}`)}>Encounters</Button>
                          <Text>{appointment.description}</Text>
                          <Tag colorScheme="teal" variant={'outline'}>
                            {new Date(appointment.startTime).toLocaleDateString()} {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                          </Tag>
                        </VStack>
                        <HStack spacing={2}>
                          <Button size="sm" colorScheme="yellow" onClick={() => handleUpdateSchedule(appointment)}>
                            <Icon as={FaEdit} />
                          </Button>
                          <Button size="sm" colorScheme="red" onClick={() => handleDeleteSchedule(appointment.id)}>
                            <Icon as={FaTrash} />
                          </Button>
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>

            {/* Cancelled Appointments */}
            <Box p={6} borderRadius="md" boxShadow="md" bg="red.100">
              <Heading as="h3" size="md" mb={4} color={'red.700'}>
                <Icon as={FaRemoveFormat} mr={2} />
                Cancelled Appointments
              </Heading>
              {appointments.cancelled.length === 0 ? (
                <Text>No cancelled appointments.</Text>
              ) : (
                <VStack align="start" spacing={3}>
                  {appointments.cancelled.map((appointment) => (
                    <Box key={appointment.id} w="full" p={4} borderRadius="md" bg="white">
                      <HStack justify="space-between">
                        <VStack align="start">
                          <Tag><Text>{appointment.name}</Text></Tag>
                          <Tag colorScheme='green'><Text>{appointment.service}</Text></Tag>
                          <Button colorScheme='teal' size={'sm'} onClick={()=>navigate(`/encounters/${appointment.id}`)}>Encounters</Button>

                          <Text>{appointment.description}</Text>
                          <Tag colorScheme="teal" variant={'outline'}>
                            {new Date(appointment.startTime).toLocaleDateString()} {new Date(appointment.startTime).toLocaleTimeString()} - {new Date(appointment.endTime).toLocaleTimeString()}
                          </Tag>

                        </VStack>
                        <HStack spacing={2}>
                          <Button size="sm" colorScheme="yellow" onClick={() => handleUpdateSchedule(appointment)}>
                            <Icon as={FaEdit} />
                          </Button>
                          <Button size="sm" colorScheme="red" onClick={() => handleDeleteSchedule(appointment.id)}>
                            <Icon as={FaTrash} />
                          </Button>
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>

          </SimpleGrid>
        </Box>
      </Box>

      {/* Edit Schedule Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Appointment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="name" isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input {...register('name')} readOnly={true} />
              </FormControl>

              <FormControl id="description" mt={4} readOnly={true} isInvalid={errors.description}>
                <FormLabel>Description</FormLabel>
                <Textarea {...register('description')} />
              </FormControl>

              <FormControl id="startTime" mt={4} isInvalid={errors.startTime}>
                <FormLabel>Start Time</FormLabel>
                <Input type="datetime-local" {...register('startTime')} />
              </FormControl>

              <FormControl id="endTime" mt={4} isInvalid={errors.endTime}>
                <FormLabel>End Time</FormLabel>
                <Input type="datetime-local" {...register('endTime')} />
              </FormControl>

              <FormControl id="status" mt={4} isInvalid={errors.status}>
                <FormLabel>Status</FormLabel>
                <Select {...register('status')}>
                  <option value="started">Started</option>
                  <option value="completed">Completed</option>
                  <option value="scheduled">Scheduled</option>
                  <option value="pending">Pending</option>
                  <option value="missed">Missed</option>
                  <option value="cancelled">Cancelled</option>

                </Select>
              </FormControl>

              <Button mt={4} colorScheme="teal" type="submit">Save</Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import {
  Box, HStack, Avatar, VStack, Heading, Text, IconButton,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
  ModalBody, ModalCloseButton, Button, useDisclosure,
  Tag, useToast, Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Spinner
} from "@chakra-ui/react";
import { CopyIcon, EditIcon } from "@chakra-ui/icons";
import TenantUpdatePage from '../../pages/UpdateProfile';
import { LINK_URL, createHandle } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const UserProfileBox = ({ profile, user }) => {
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const [newHandle, setNewHandle] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: 'Link copied.',
        description: 'Landing page link has been copied to clipboard.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const handleCreateHandle = async () => {
    setLoading(true); // Start the loader
    try {
      const trimmedHandle = newHandle.trim();
      if (!trimmedHandle) {
        toast({
          title: 'Invalid handle.',
          description: 'Handle cannot be empty.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const response = await createHandle(trimmedHandle);
      
      if (response.data.message === "Handle Created Successfully") {
        toast({
          title: 'Handle created.',
          description: `Handle '${trimmedHandle}' has been created.`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        onCreateClose();
        navigate(0);
      } else {
        toast({
          title: 'Error creating handle.',
          description: 'There was an issue creating the handle. Please try again.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Request failed.',
        description: 'Unable to create handle. Please try again.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  return (
    <Box position="relative" p={6} borderRadius="md" boxShadow="md">
      <IconButton
        icon={<EditIcon />}
        position="absolute"
        top={2}
        right={2}
        aria-label="Edit profile"
        onClick={onEditOpen}
      />
      <HStack spacing={4}>
        <Avatar size="md" name={`${profile.firstName} ${profile.lastName}`} src={user.photoURL} />
        <VStack align="start" spacing={1}>
          <Heading as="h3" size="lg" color={'teal'}>{`${profile.firstName} ${profile.lastName}`}</Heading>
          <Text fontSize="sm">{profile.email}</Text>
          <Text fontSize="sm">Plan: <Tag colorScheme='orange'>{profile.role}</Tag></Text>
          <HStack>
            <Text fontSize="sm">Link:</Text>
            {profile.handle ? 
              (
                <>
                  <Button onClick={() => navigate(`/${profile.handle}`)}>
                    <Text fontSize="sm">
                      <Tag colorScheme='teal'>landing page link</Tag>
                    </Text>
                  </Button>
                  <IconButton
                    aria-label="Copy link"
                    icon={<CopyIcon />}
                    onClick={() => copyToClipboard(`${LINK_URL}/${profile.handle}`)}
                  />
                
                </>
              ) : 
              (
                <InputGroup width="full">
                <InputLeftAddon>{LINK_URL}/</InputLeftAddon>
                <Input
                  placeholder="Enter new handle"
                  value={newHandle}
                  onChange={(e) => setNewHandle(e.target.value)}
                  width="50"
                />
                <InputRightAddon>
                  <Button 
                    colorScheme="teal" 
                    onClick={handleCreateHandle} 
                    isLoading={loading} // Show spinner when loading
                  >
                    Claim
                  </Button>
                </InputRightAddon>
              </InputGroup>
              )
            }
          </HStack>
        </VStack>
      </HStack>

      {/* Edit Profile Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TenantUpdatePage tenantData={profile} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onEditClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Create Handle Modal */}
      <Modal isOpen={isCreateOpen} onClose={onCreateClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Handle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup width="full">
              <InputLeftAddon>{LINK_URL}/</InputLeftAddon>
              <Input
                placeholder="Enter new handle"
                value={newHandle}
                onChange={(e) => setNewHandle(e.target.value)}
                width="100%"
              />
              <InputRightAddon>
                <Button 
                  colorScheme="teal" 
                  onClick={handleCreateHandle} 
                  isLoading={loading} // Show spinner when loading
                >
                  Claim
                </Button>
              </InputRightAddon>
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCreateClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserProfileBox;

import React, { useState } from 'react';
import { updateNotificationPreferences, updateUserProfile } from '../services/api';
import {
    Box, FormControl, FormLabel, Input, Checkbox, Button, VStack, Heading, Divider, useToast,
    Textarea
} from '@chakra-ui/react';

const TenantUpdatePage = ({ tenantData }) => {
    const [basicInfo, setBasicInfo] = useState({
        firstName: tenantData.firstName,
        lastName: tenantData.lastName,
        phoneNumber: tenantData.phoneNumber,
        profession: tenantData.profession,
        bio: tenantData.bio,
        bank:tenantData.bank,
        accountName:tenantData.accountName,
        accountNumber:tenantData.accountNumber
    });

    const [notificationPrefs, setNotificationPrefs] = useState(tenantData.notificationPreferences);
    const [isLoadingBasicInfo, setIsLoadingBasicInfo] = useState(false);
    const [isLoadingNotificationPrefs, setIsLoadingNotificationPrefs] = useState(false);
    const toast = useToast();

    const handleBasicInfoChange = (e) => {
        const { name, value } = e.target;
        setBasicInfo((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleNotificationPrefsChange = (e) => {
        const { name, checked } = e.target;
        setNotificationPrefs((prevState) => ({ ...prevState, [name]: checked }));
    };

    const handleBasicInfoSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingBasicInfo(true);
        try {
            await updateUserProfile(basicInfo);
            toast({
                title: 'Success',
                description: 'Basic info updated successfully',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update basic info',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        } finally {
            setIsLoadingBasicInfo(false);
        }
    };

    const handleNotificationPrefsSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingNotificationPrefs(true);
        try {
            await updateNotificationPreferences(notificationPrefs);
            toast({
                title: 'Success',
                description: 'Notification preferences updated successfully',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update notification preferences',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        } finally {
            setIsLoadingNotificationPrefs(false);
        }
    };

    return (
        <Box p={4}>            
            <VStack spacing={6} align="stretch">
                <Box>
                    <Heading as="h2" size="md" mb={4}>Basic Information</Heading>
                    <form onSubmit={handleBasicInfoSubmit}>
                        <VStack spacing={4} align="stretch">
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    type="text"
                                    name="firstName"
                                    value={basicInfo.firstName}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    type="text"
                                    name="lastName"
                                    value={basicInfo.lastName}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Professional Title</FormLabel>
                                <Input
                                    type="text"
                                    name="profession"
                                    value={basicInfo.profession}
                                    placeholder='e.g Doctor, Lawyer, etc'
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Bio</FormLabel>
                                <Textarea
                                    type="text"
                                    name="bio"
                                    value={basicInfo.bio}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    type="tel"
                                    name="phoneNumber"
                                    value={basicInfo.phoneNumber}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Bank</FormLabel>
                                <Input
                                    type="text"
                                    name="bank"
                                    value={basicInfo.bank}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Account Name</FormLabel>
                                <Input
                                    type="text"
                                    name="accountName"
                                    value={basicInfo.accountName}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Account Number</FormLabel>
                                <Input
                                    type="text"
                                    name="accountNumber"
                                    value={basicInfo.accountNumber}
                                    onChange={handleBasicInfoChange}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="teal" isLoading={isLoadingBasicInfo}>
                                Update Basic Info
                            </Button>
                        </VStack>
                    </form>
                </Box>
                
                <Divider />

                <Box>
                    <Heading as="h2" size="md" mb={4}>Notification Preferences</Heading>
                    <form onSubmit={handleNotificationPrefsSubmit}>
                        <VStack spacing={4} align="stretch">
                            <FormControl display="flex" alignItems="center">
                                <Checkbox
                                    name="emailNotifications"
                                    isChecked={notificationPrefs.emailNotifications}
                                    onChange={handleNotificationPrefsChange}
                                >
                                    Email Notifications
                                </Checkbox>
                            </FormControl>
                            <FormControl display="flex" alignItems="center">
                                <Checkbox
                                    name="smsNotifications"
                                    isChecked={notificationPrefs.smsNotifications}
                                    onChange={handleNotificationPrefsChange}
                                >
                                    SMS Notifications
                                </Checkbox>
                            </FormControl>
                            {/* Add other fields as necessary */}
                            <Button type="submit" colorScheme="teal" isLoading={isLoadingNotificationPrefs}>
                                Update Notification Preferences
                            </Button>
                        </VStack>
                    </form>
                </Box>
            </VStack>
        </Box>
    );
};

export default TenantUpdatePage;

// src/pages/ThankYouPage.js
import React from 'react';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const RegisteredPage = () => {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" py={10} px={6} bg="teal.50">
    <Heading as="h2" size="xl" mb={4} color="teal.700">Welcome!</Heading>
    <Text fontSize="lg" mb={4} color="teal.600">Thank you for providing your details! We appreciate your effort in setting up your profile.
         Now, let's move on to selecting a plan that best suits your needs and get the most out of PinePlanner.</Text>
    </Box>
  );
};

export default RegisteredPage;

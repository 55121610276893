import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  useToast,
  Icon,
  Button,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Badge,
} from '@chakra-ui/react';
import { FiCalendar, FiClock, FiCheckCircle, FiMessageCircle, FiPhone, FiMail } from 'react-icons/fi';
import { getProfessionalSchedules, getUserAppointments } from '../services/api';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaMessage, FaPerson } from 'react-icons/fa6';
import { BsFilePerson, BsFillEnvelopeArrowDownFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Schedules = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue('gray.10', 'gray.100');
  const toast = useToast();
const navigate=useNavigate();
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const userAppointments = await getProfessionalSchedules();
        setAppointments(userAppointments?.data || []);
      } catch (error) {
        console.error('Error fetching appointments:', error);
        toast({
          title: 'Error fetching appointments.',
          description: 'There was an error loading your appointments. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [toast]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (appointments.length === 0) {
    return (
      <Flex>
        <Sidebar isOpen={isOpen} onToggle={handleToggle} />
        <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
          <Header isOpen={isOpen} onToggle={handleToggle} title={"My Schedules"}/>
          <Flex align="center" justify="center" height="100vh">
            
            <Text>No appointments scheduled</Text>
          </Flex>
        </Box>
      </Flex>

    );
  }

  return (
    <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"My Schedules"}/>
        <Box p={4} mt="80px">
          <SimpleGrid columns={[1, 2, 3, 4]} spacing={6}>
            {appointments.map((appointment) => (
              <Box
                key={appointment.id}
                p={4}
                width="full"
                bg={boxBg}
                borderRadius="md"
                boxShadow="md"
              >
                <VStack align="start" spacing={2}>
                  <Text fontWeight="bold" fontSize="lg">{appointment.description}</Text>
                  <HStack>
                    <Icon as={BsFilePerson} color={'teal'} />
                    <Badge>
                      <Text fontWeight="bold" fontSize="md">{appointment.name}</Text></Badge>

                  </HStack>
                  <HStack>
                    <Icon as={FiCalendar} color={'teal'} />
                    <Text>{new Date(appointment.startTime).toLocaleDateString()}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiClock} color={'teal'} />
                    <Text>{new Date(appointment.startTime).toLocaleTimeString()}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiClock} color={'teal'} />
                    <Text>{new Date(appointment.endTime).toLocaleTimeString()}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiMail} color={'teal'} />
                    <Text>{appointment.email}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiPhone} color={'teal'} />
                    <Text>{appointment.phoneNumber}</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiCheckCircle} color={'teal'} />
                    <Text>Status: {appointment.status}</Text>
                  </HStack>
                  <Button colorScheme='teal' variant={'outline'} size={'sm'} onClick={() => navigate(`/encounters/${appointment.id}`)}>Encounters</Button>

                  {/* <Menu>
                    <MenuButton colorScheme='teal' as={Button} size={'sm'} variant={'outline'} rightIcon={<ChevronDownIcon />}>
                      Manage
                    </MenuButton>
                    <MenuList>
                      
                      <MenuItem onClick={() => navigate(`/encounters/${appointment.id}`)}>View encounters</MenuItem>
                      <MenuItem>Buzz</MenuItem>
                      <MenuItem>Snooze</MenuItem>
                      <MenuItem>Edit</MenuItem>
                      <MenuItem><Divider /></MenuItem>
                      <MenuItem>Cancel</MenuItem>
                    </MenuList>
                  </Menu> */}
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  );
};

export default Schedules;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
} from '@chakra-ui/react';
import { getUserAppointments } from '../services/api';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import { useNavigate } from 'react-router-dom';

const History = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue('gray.50', 'gray.700');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const userAppointments = await getUserAppointments();
        console.log('User Appointments:', userAppointments); // Log appointments data
        setAppointments(userAppointments?.data || []);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (appointments.length === 0) {
    <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"Appointment History"} />
        <Flex align="center" justify="center" height="100vh">
          <Text>No Data</Text>
        </Flex>
      </Box>
    </Flex>
  }

  return (
    <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={'Appointment History'} />
        <Box p={4} mt="80px">
          <TableContainer>
            <Table variant="simple" colorScheme={'teal'} size="xs">
              <TableCaption>Appointment History</TableCaption>
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Start Time</Th>
                  <Th>End Time</Th>
                  <Th>Status</Th>
                  <Th>Encounters</Th>

                </Tr>
              </Thead>
              <Tbody>
                {appointments.map((appointment) => (
                  <Tr key={appointment.id}>
                    <Td>{appointment.description}</Td>
                    <Td>{new Date(appointment.startTime).toLocaleString()}</Td>
                    <Td>{new Date(appointment.endTime).toLocaleString()}</Td>
                    <Td>{appointment.status}</Td>
                    <Td>                        
                        <Button colorScheme='teal' variant={'outline'} size={'sm'} onClick={() => navigate(`/encounters/${appointment.id}`)}>Encounters</Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
  );
};

export default History;

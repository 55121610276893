import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useDisclosure,
  useToast,
  Divider,
  Select,
} from '@chakra-ui/react';
import { getUserServices, createService } from '../services/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';

const MyServices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue('gray.50', 'gray.700');
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const toast = useToast();
  const currencies = [
    { code: "USD", name: "United States Dollar" },
    { code: "NGN", name: "Nigerian Naira" },
  ];
  const schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    price: yup.number().required().positive(),
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const userServices = await getUserServices();
        console.log('User Services:', userServices); // Log services data
        setServices(userServices?.data || []);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    try {
      const newService = await createService(data);
      setServices([...services, newService.data]);
      toast({
        title: "Service created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onModalClose();
      reset(); // Reset form fields after successful submission
    } catch (error) {
      toast({
        title: "Failed to create service.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"My Services"} />
        <Box p={4} mt="80px" position="relative">
          <Divider height={20} />

          <Button
            onClick={onModalOpen}
            colorScheme="teal"
            position="absolute"
            top="4"
            right="4"
            size={'sm'}
          >
            Create Service
          </Button>

          <Divider height={20} />

          {services.length === 0 ? (
            <Flex align="center" justify="center" height="100vh">
              <Text>No services found</Text>
            </Flex>
          ) : (
            <TableContainer>
              <Table variant="simple" colorScheme={'teal'} size="xs">
                <TableCaption> My Services</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th>Created At</Th>
                    <Th>Updated AT</Th>
                    <Th>Currency</Th>
                    <Th>Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {services.map((service) => (
                    <Tr key={service.id}>
                      <Td>{service.name}</Td>
                      <Td>{service.description}</Td>
                      <Td>{new Date(service.createdAt).toLocaleString()}</Td>
                      <Td>{new Date(service.updatedAt).toLocaleString()}</Td>
                      <Td>{service.currency}</Td>
                      <Td>{service.price}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      {/* Create Service Modal */}
      <Modal isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="name" isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input {...register('name')} />
              </FormControl>

              <FormControl id="description" mt={4} isInvalid={errors.description}>
                <FormLabel>Description</FormLabel>
                <Textarea {...register('description')} />
              </FormControl>

              <FormControl id="currency" isRequired>
                <FormLabel>What currency would you like your clients to pay you in?</FormLabel>
                <Select {...register('currency')}>
                  {currencies.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.name} ({currency.code})
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="price" mt={4} isInvalid={errors.price}>
                <FormLabel>Price</FormLabel>
                <Input type="number" {...register('price')} />
              </FormControl>


              <Button mt={4} colorScheme="teal" type="submit">Save</Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onModalClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MyServices;

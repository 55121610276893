import * as React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  Flex,
  Heading,
  useColorMode,
  useColorModeValue,
  useToast,
  Spinner,
  InputRightElement,
  InputGroup,
  Link,
  IconButton,
} from '@chakra-ui/react';
import { FaRegUserCircle } from 'react-icons/fa';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import '../services/firebase'; // Make sure this is the correct path to your firebase.js
import { auth, registerWithEmailAndPassword } from '../services/firebase';
import Footer from '../components/common/Footer';
import {SecondaryLogo} from '../components/common/LogoPine';


export default function SignUp() {
  const bg = useColorModeValue('gray.100', 'gray.900');
  const boxBg = useColorModeValue('white', 'gray.700');
  const formBg = useColorModeValue('white', 'gray.800');
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const hideShowPassword = () => setShow(!show);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: 'Passwords do not match.',
        description: 'Please ensure the passwords match.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      await registerWithEmailAndPassword(email, password);
      setLoading(false);
      navigate('/'); // Redirect to the dashboard after sign-up
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error signing up.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <ChakraProvider>
      <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
        <Box
          w={{ base: '100%', md: '50%' }}
          minH={{ base: '50vh', md: '100vh' }}
          bgImage="/assets/images/background.png"
          bgSize="cover"
          bgPosition="center"
          position="relative"
        />
        <Flex
          w={{ base: '100%', md: '50%' }}
          minH={{ base: '50vh', md: '100vh' }}
          align="center"
          justify="center"
          bg={bg}
          px={4}
        >
          <Box w="full" maxW="lg" bg={boxBg} boxShadow="md" p={6} borderRadius="md">
            <Flex justify="space-between" align="center" mb={6}>
              <Flex align="center">
                <SecondaryLogo/>
              </Flex>
            </Flex>

            <Box as="main" bg={formBg} p={6} borderRadius="md" boxShadow="md">
              <Stack spacing={4} mb={6}>
                <Stack spacing={1}>
                  <Heading as="h1" color={'teal'} size="lg">Sign Up</Heading>
                  <Text fontSize="sm">
                    Already have an account?{' '}
                    <Link href="/sign-in" color="teal">Sign in!</Link>
                  </Text>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={4} mt={6}>
                <form onSubmit={handleSignUp}>
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" name="email" value={email}
                      onChange={(e) => setEmail(e.target.value)} required />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input name="password" value={password} type={show ? 'text' : 'password'}
                        onChange={(e) => setPassword(e.target.value)} required />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' color={'teal'} variant={'outline'} onClick={hideShowPassword}>
                          {show ? 'Hide' : 'Show'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Confirm Password</FormLabel>
                    <InputGroup>
                      <Input name="confirmPassword" value={confirmPassword} type={show ? 'text' : 'password'}
                        onChange={(e) => setConfirmPassword(e.target.value)} required />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' color={'teal'} variant={'outline'} onClick={hideShowPassword}>
                          {show ? 'Hide' : 'Show'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack spacing={4} mt={2}>
                    <Button isLoading={loading} colorScheme="teal" w="full" type="submit">
                      Sign Up 
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </Box>

            <Footer />
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

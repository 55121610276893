import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  useToast,
  Text,
  Heading,
  VStack,
  HStack,
  Spacer,
  Progress,
  Textarea,
  Select,
} from '@chakra-ui/react';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import { getUserProfile, createUserProfile } from '../services/api';
import Dashboard from './Dashboard';
import Loader from '../components/common/Loader';
import RegisteredPage from '../components/User/RegisteredPage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../services/firebase';
import FreeSideBar from '../components/common/FreeSideBar';
import { useNavigate } from 'react-router-dom';
import PricingDollar from '../components/Billing/pricing-dollar';
import PricingNaira from '../components/Billing/pricing-naira';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(null);
  const [genericResponse, setGenericResponse] = useState();
  const [loading, setLoading] = useState(true);
  const [user] = useAuthState(auth);
  const [showError, setShowError] = useState(false);
  var navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: user.email,
    profession: '',
    bio: '',
    emailNotifications: false,
    smsNotifications: false,
    syncWithGoogleCalendar: false,
    bank: '',
    accountName: '',
    accountNumber: '',
    country: '',
    currency: ''

  });

  const countries = [
    { code: "US", name: "United States" },
    { code: "GB", name: "United Kingdom" },
    { code: "IN", name: "India" },
    { code: "AU", name: "Australia" },
    { code: "NG", name: "Nigeria" },
    { code: "JP", name: "Japan" },
    { code: "OTHER", name: "Other" },

  ];



  const [activeStep, setActiveStep] = useState(0);
  const toast = useToast();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserProfile();
        console.log("Helloooooo:" + response.status);
        setProfile(response?.data);

      } catch (error) {
        if (error.response && error.response.status === "404") {
          // The request was made and the server responded with a status code
          console.log('Server responded with a status:', error.response.status);

          setProfile(null);
          setShowError(false);


        } else if (error.request) {
          // The request was made but no response was received
          console.log('No response received:', error.request);
          setShowError(true);

        } else {
          // Something happened in setting up the request
          console.log('Error in setting up the request:', error.message);
          setProfile(null);

        }

      } finally {
        setLoading(false);


      }
    };
    fetchProfile();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUserProfile({
        ...formData,
        notificationPreferences: {
          emailNotifications: formData.emailNotifications,
          smsNotifications: formData.smsNotifications,
        },
      });
      toast({
        title: 'Profile created.',
        description: 'Your profile has been created successfully.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setProfile(formData); // Set profile to stop showing the form
      setActiveStep(1); // Move to the next step
    } catch (error) {
      console.error('Error creating profile:', error);
      toast({
        title: 'Error.',
        description: 'There was an error creating your profile.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const steps = [
    {
      label: 'Fill Form',
      content: (
        profile ? (
          <RegisteredPage />
        ) : (
          <Box as="form" onSubmit={handleSubmit} p={6} borderRadius="md" boxShadow="lg">
            <VStack spacing={4} align="stretch">
              <Heading as="h2" color='teal' size="lg">Welcome to PinePlanner!</Heading>
              <Text>Please fill in your details below. This is a one-time process.</Text>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="profession" isRequired>
                <FormLabel>Professional Title</FormLabel>
                <Input
                  type="text"
                  name="profession"
                  placeholder='e.g Doctor, Lawyer, etc'
                  value={formData.profession}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="bio" isRequired>
                <FormLabel>Bio</FormLabel>
                <Textarea
                  type="text"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="phoneNumber" isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                  type="phone"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  readOnly="true"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="country" isRequired>
                <FormLabel>Country</FormLabel>
                <Select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  placeholder={'Select your country...'}
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </FormControl>


              <FormControl id="bank" isRequired>
                <FormLabel>Bank</FormLabel>
                <Input
                  type="text"
                  name="bank"
                  placeholder='Enter the name of your bank'
                  value={formData.bank}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="accountName" isRequired>
                <FormLabel>Account Name</FormLabel>
                <Input
                  type="text"
                  name="accountName"
                  placeholder='Enter your account name'
                  value={formData.accountName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="accountNumber" isRequired>
                <FormLabel>Account Number</FormLabel>
                <Input
                  type="number"
                  name="accountNumber"
                  placeholder='Enter your account number'
                  value={formData.accountNumber}
                  onChange={handleChange}
                />
              </FormControl>

              <HStack spacing={4} align="center">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    name="emailNotifications"
                    isChecked={formData.emailNotifications}
                    onChange={handleChange}
                  >
                    Email Notifications
                  </Checkbox>
                </FormControl>
                <Spacer />
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    name="smsNotifications"
                    isChecked={formData.smsNotifications}
                    onChange={handleChange}
                  >
                    SMS Notifications
                  </Checkbox>
                </FormControl>

           
                {/* <FormControl display="flex" alignItems="center">
                <Checkbox
                  name="syncWithGoogleCalendar"
                  isChecked={formData.syncWithGoogleCalendar}
                  onChange={handleChange}
                >
                  Sync With Google Calendar
                </Checkbox>
              </FormControl> */}
              </HStack>
              <FormControl id="referer">
                <FormLabel>Referrer</FormLabel>
                <Input
                  type="text"
                  name="referer"
                  value={formData.referer}
                  onChange={handleChange}
                />
              </FormControl>
              <Button type="submit" colorScheme="teal" width="full">
                Submit
              </Button>
            </VStack>
          </Box>
        )
      ),
    },
    {
      label: 'Choose Plan',
      content: (
        profile ? (profile.country === 'NG' ? <PricingNaira profile={profile} /> : <PricingDollar profile={profile} />) : (
          <Box textAlign="center" py={10} px={6} bg="red.50">
            <Heading as="h2" size="xl" mb={4} color="red.700">You haven't filled your details!</Heading>
            <Text fontSize="lg" mb={4} color="red.600">You have not filled in your details, kindly do so to access this step</Text>
          </Box>
        ))
    },
  ];

  if (loading) {
    return <Loader />;
  }
  if (showError) {
    return <Box textAlign="center" py={10} px={6} bg="red.50">
      <Heading as="h2" size="xl" mb={4} color="red.700">Network Error</Heading>
      <Text fontSize="lg" mb={4} color="red.600">Sorry, we can't reach the server. Try again in a moment</Text>
      <Button colorScheme='red' onClick={() => navigate('/')}>Reload</Button>
    </Box>
  }
  return (

    <Flex>
      {profile && profile.subscriptionStatus === "active" &&
        <Sidebar isOpen={isOpen} onToggle={handleToggle} />}
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        {profile && profile.subscriptionStatus === "active" &&
          <Header isOpen={isOpen} onToggle={handleToggle} title={"Dashboard"} />}
        <Box p={4} mt="80px">
          {profile && profile.subscriptionStatus === "active" ?
            (<Dashboard currency={profile.country==="NG"? "NGN":"USD"}/>) :
            <Box bg="white" p={6} borderRadius="md" >
              <VStack spacing={6}>
                <Progress value={(activeStep / steps.length) * 100} width="100%" colorScheme='teal' />
                {steps[activeStep].content}
                <HStack spacing={4} mt={4}>
                  {activeStep > 0 && (
                    <Button onClick={() => setActiveStep(activeStep - 1)}>
                      Previous
                    </Button>
                  )}
                  {activeStep < steps.length - 1 && (
                    <Button onClick={() => setActiveStep(activeStep + 1)} colorScheme="teal">
                      Next
                    </Button>
                  )}
                </HStack>
                <Button onClick={() => logout()} colorScheme="red">
                  Sign Out
                </Button>
              </VStack>
            </Box>
          }

        </Box>
      </Box>
    </Flex>
  );
};

export default Home;

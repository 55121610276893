import React, { useEffect } from 'react';
import {
  Flex,
  IconButton,
  useColorModeValue,
  Heading,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Button,
  Tag,
  Spacer,
} from '@chakra-ui/react';


import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaUser, FaCog, FaBell, FaSignOutAlt } from 'react-icons/fa';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';


const Header = ({ isOpen, onToggle, title }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading, navigate]);

  const bg = useColorModeValue('gray.200', 'gray.900');
  const color = useColorModeValue('black', 'white');

  const handleLogout = () => {
    logout();
  };

  return (
    <Flex
      as="header"
      width="100%"
      height={"50px"}
      bg={bg}
      color={color}
      p={4}
      alignItems="center"
      justifyContent="space-between"
      boxShadow="sm"
      position="fixed"
      zIndex="1"
      top="0"
    >
      <Box p={'4'}><IconButton
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        onClick={onToggle}
        aria-label="Toggle Sidebar"
        size="lg"
        zIndex={1}
        mr={4}
      />
        {/* <Image src="/assets/images/logo.png" alt="PinePlanner Logo" boxSize="50px" mr={2} /> */}
      </Box>
      <Spacer />
      <Heading size={'lg'} color={'teal'}>{title}</Heading>
      <Spacer />

      <Box p={'4'}>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            variant="ghost"
            display="flex"
            alignItems="center"
            _focus={{ boxShadow: 'none' }} // To remove focus outline on the button
          >
            <Avatar size="xs" name={user ? user.email : ''} src={user ? user.photoURL : ''} />
          </MenuButton>
          <MenuList placement="bottom-end" zIndex="tooltip">
            {/* <MenuItem icon={<FaUser />}>Profile</MenuItem>
            <MenuItem icon={<FaCog />}>Settings</MenuItem> */}
            <MenuItem icon={<FaBell />} onClick={() => navigate("/notifications")}>Notifications</MenuItem>
            <MenuDivider />
            <MenuItem icon={<FaSignOutAlt />} onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

    </Flex>
  );
};

export default Header;

import React from 'react';
import { Box, Link, VStack, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaCalendarAlt, FaClipboardList, FaCog, FaChartPie, FaGoogle, FaServicestack, FaComment } from 'react-icons/fa';
import { FaMoneyBill, FaPeopleGroup } from 'react-icons/fa6';

const Sidebar = ({ isOpen }) => {
  const bg = useColorModeValue('teal', 'gray.900');
  const color = useColorModeValue('white', 'white');
  const hoverBg = useColorModeValue('gray.300', 'gray.700');

  const links = [
    { to: "/", label: "Home", icon: FaHome },
    { to: "/schedules", label: "Bookings", icon: FaCalendarAlt },
    { to: "/history", label: "History", icon: FaClipboardList },
    { to: "/my-clients", label: "Clients", icon: FaPeopleGroup },
    { to: "/my-services", label: "Services", icon: FaServicestack },
    // { to: "/reviews", label: "Reviews", icon: FaComment },

    // { to: "/google-calendar-sync", label: "Syncs and Backups", icon: FaGoogle },

    // { to: "/billing", label: "Billing", icon: FaMoneyBill },
    // { to: "/report", label: "Reports", icon: FaChartPie },

    // { to: "/settings", label: "Settings", icon: FaCog },
  ];

  return (
    <Box
      bg={bg}
      w={isOpen ? "250px" : "60px"}
      p={4}
      color={color}
      height="100vh"
      position="fixed"
      transition="width 0.3s"
      overflow="hidden"
    >
      <VStack spacing={4} align="stretch">
        {links.map(link => (
          <Link
            as={RouterLink}
            to={link.to}
            p={2}
            borderRadius="md"
            _hover={{ bg: hoverBg }}
            key={link.to}
          >
            <HStack spacing={isOpen ? 4 : 0} justify={isOpen ? "start" : "center"}>
              <link.icon />
              {isOpen && <Text>{link.label}</Text>}
            </HStack>
          </Link>
        ))}
      </VStack>
    </Box>
  );
};

export default Sidebar;

import axios from 'axios';
import { auth } from '../services/firebase'; // Make sure this is the correct path to your firebase.js
import { useToast } from '@chakra-ui/react';
import { onAuthStateChanged } from 'firebase/auth';

// Set the base URL for your API
// const API_BASE_URL = 'http://localhost:8081';
const API_BASE_URL = 'https://appointex-backend-sqmovepkwq-uc.a.run.app';
export const LINK_URL = 'https://app.pineplanner.space'


// Configure Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


let isAuthenticated = false;
let token='';

onAuthStateChanged(auth, async (user) => {
  if (user) {
    isAuthenticated = true;
    token=await user.getIdToken();
  } else {
    isAuthenticated = false;
  }
});


// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response error
    var toast=useToast();

    toast({
      title: 'Server error',
      description: 'The server returned an error. Please try again later.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return Promise.reject(error);
  }
);

// Handle token-based authentication


api.interceptors.request.use(async (config) => {
  if (isAuthenticated) {
    try {
        config.headers.Authorization = `Bearer ${token}`;    
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Define API functions

// User Profile
export const getUserProfile = async () => {
  return await api.get('/api/users/profile');
};

export const createUserProfile = async (profileData) => {
  return await api.post('/api/users/register', profileData);
};
export const updateUserProfile = (profileData) => {
  return api.put('/api/users/update', profileData);
};

export const updateNotificationPreferences = (preferenceData) => {
  return api.put('/api/users/notificationPreferences', preferenceData);
};

// Appointments

export const getProfessionalSchedules = async () => {
  return await api.get('/api/appointments/getMySchedules');
};
export const getUserAppointments = async () => {
  return await api.get('/api/appointments/getMyAppointments');
};
export const getNotifications = () => {
  return api.get('/api/notifications/getMyNotifications');
};
export const verifyTransaction = (tx_ref, transaction_id, status) => {
  const plans = ["BASIC", "STANDARD", "PROFESSIONAL"];

  // Check if tx_ref contains any of the plans
  const plan = plans.find(p => tx_ref.includes(p));

  if (plan) {
    return api.get(`/api/webhooks/verify-transaction-plan/${tx_ref}/${transaction_id}/${status}`);
  } else {
    return api.get(`/api/webhooks/verify-transaction/${tx_ref}/${transaction_id}/${status}`);
  }
};


export const createGuestAppointment = (appointmentData) => {
  return api.post('/api/appointments/create-guest-appointment', appointmentData);
};
export const createAppointment = (appointmentData) => {
  return api.post('/api/appointments/create-appointment', appointmentData);
};


export const updateAppointment = (appointmentId, appointmentData) => {
  return api.put(`/api/appointments/update/${appointmentId}`, appointmentData);
};

export const deleteAppointment = (appointmentId) => {
  return api.delete(`/api/appointments/delete/${appointmentId}`);
};



// Notifications
export const getUserNotifications = async () => {
  return await api.get('/api/notifications/getMyNotifications');
};

export const markNotificationAsRead = (notificationId) => {
  return api.put(`/api/notifications/${notificationId}/read`);
};

export const deleteNotification = (notificationId) => {
  return api.delete(`/api/notifications/${notificationId}`);
};


//public

export const getProfessionalProfile = async (handle) => {
  try {
    return await api.get(`/api/public/professional/${handle}`);
  } catch (error) {
    console.error('Error fetching professional profile:', error);
    throw error;
  }
};


export const getProfessionals = async () => {
  return await api.get("/api/public/marketplace");
};
export const getServicesByProfessionalID = async (professionalId) => {
  return await api.get(`/api/public/services/${professionalId}`);
};

export const createCheckoutSession = async (paymentData) => {
  return await api.post('/api/payments/create-checkout-session-flutterwave', paymentData);
};

export const createFreeSubscription = async (paymentData) => {
  return await api.post('/api/payments/create-free-subscription', paymentData);
};

export const logPageViews = async (professionalId) => {
  try {
    return await api.get(`/api/public/log-page-views/${professionalId}`);
  } catch (error) {
    console.error('Error logging page view:', error);
    throw error;
  }
};

export const logClicks = async (professionalId) => {
  try {
    return await api.get(`/api/public/log-clicks/${professionalId}`);
  } catch (error) {
    console.error('Error logging click:', error);
    throw error;
  }
};

//Stats
export const getStatistics = async (professionalId) => {
  return await api.get(`/api/statistics/business-statistics/${professionalId}`);
};

export const getReferalCount = async (handle) => {
  return await api.get(`/api/users/referal-count/${handle}`);
};


//Clients

export const getClients = async () => {
  return await api.get("/api/clients/my-clients");
};


//Services

export const getUserServices = async () => {
  return await api.get("/api/services/my-services");
}

export const createService = async (serviceData) => {
  return await api.post('/api/services/create', serviceData);
};


//Encounters

export const getEncounters = async (appointmentId) => {
  return await api.get(`/api/encounters/getEncounters/${appointmentId}`);
}

export const createEncounter = async (encounterData) => {
  return await api.post('/api/encounters/create', encounterData);
};


export const updateEncounter = (encounterId, encounterData) => {
  return api.put(`/api/encounters/update/${encounterId}`, encounterData);
};

export const deleteEncounter = (encounterId) => {
  return api.delete(`/api/encounters/delete/${encounterId}`);
};



export const createHandle = async (handle) => {
  return await api.get(`/api/users/create-handle/${handle}`);
}
//Feedback
export const createFeedback = async (feedbackData) => {
  return await api.post('/api/appoinments/create-acknowledgement',feedbackData);
}


//Analytics


export const getCustomAnalytics = async () => {
  return await api.get(`/api/statistics/analytics`);
}
// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, sendEmailVerification } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLhOR0VpDeNNxcfMotdVSHyQTy5sM7hQs",
  authDomain: "appointex-3c37b.firebaseapp.com",
  projectId: "appointex-3c37b",
  storageBucket: "appointex-3c37b.appspot.com",
  messagingSenderId: "1036855716445",
  appId: "1:1036855716445:web:5930c9b321065f7c71fbb4",
  measurementId: "G-1Q9NH8XSDQ"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    // googleProvider.addScope("https://www.googleapis.com/auth/calendar.events");
    
    const { user } = await signInWithPopup(auth, googleProvider);
    return user;
  } catch (error) {
    console.error(error);
    // Display error message to the user
    throw new Error("Failed to sign in with Google.");
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.error(error);
    // Display error message to the user
    throw new Error("Failed to log in with email and password.");
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(user);
    return user;
  } catch (error) {
    console.error(error);
    // Display error message to the user
    throw new Error("Failed to register with email and password.");
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    // Display success message to the user
    console.log("Password reset link sent!");
  } catch (error) {
    console.error(error);
    // Display error message to the user
    throw new Error("Failed to send password reset email.");
  }
};



const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error(error);
    // Display error message to the user
    throw new Error("Failed to log out.");
  }
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  analytics,
  signInWithPopup,
  googleProvider
};

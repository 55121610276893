import React from 'react';
import { Flex, Spinner, Text, VStack } from '@chakra-ui/react';

const Loader = ({ message = "Loading..." }) => {
  return (
    <Flex align="center" justify="center" height="100vh" width="100vw">
      <VStack spacing={4}>
        <Spinner size="xl" thickness="4px" speed="0.65s" color="teal.500" />
        <Text fontSize="lg" color="gray.500">{message}</Text>
      </VStack>
    </Flex>
  );
};

export default Loader;

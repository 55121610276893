import React from 'react';
import { Box, Text, Stack, Link, useColorModeValue } from '@chakra-ui/react';

const Footer = () => {
  const bg = useColorModeValue('gray.100', 'gray.900');
  const color = useColorModeValue('black', 'white');

  return (
    <Box as="footer" mt={6} textAlign="center">
    <Text fontSize="xs">© PinePlanner {new Date().getFullYear()}</Text>
</Box>
  );
};

export default Footer;

// SignInWithGoogle.js
import React, { useEffect, useState } from 'react';
import { auth, googleProvider, signInWithPopup } from '../services/firebase';
import axios from 'axios';
import { gapi } from 'gapi-script';
import { Box, Button, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { GoogleAuthProvider } from 'firebase/auth/web-extension';
import {  getProfessionalSchedules } from '../services/api';

const SyncWithGoogleCal = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [syncing, setSyncing] = useState(false);
  const [syncMessage, setSyncMessage] = useState('');
const [appointments,setAppointments]=useState([]);
const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schedules = await getProfessionalSchedules();
        console.log('Tenant clients:', schedules); // Log clients data
        setAppointments(schedules?.data || []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  const signInWithGoogle = async () => {
    try {
      googleProvider.addScope('https://www.googleapis.com/auth/calendar.events');
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      setAccessToken(token);
      setIsLoggedIn(true);

      // Initialize Google API client with the token
      gapi.load('client:auth2', () => {
        gapi.client.init({
        //   apiKey: 'AIzaSyDN7VWrc_rFla4JW6r3T1r22FM3FLilwak',
          clientId: '382556769072-nh5hum15pnkbl1uc4lqkovb3egen663o.apps.googleusercontent.com',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
          scope: 'https://www.googleapis.com/auth/calendar.events',
        });
        gapi.client.setToken({ access_token: token });
      });
    } catch (error) {
      console.error('Failed to sign in with Google:', error);
      setIsLoggedIn(false);
    }
  };

  const createEvent = async (event) => {
    try {
      const response = await gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });
      console.log('Event created:', response);
      return true;
    } catch (error) {
      console.error('Error creating event:', error);
      return false;
    }
  };

  const syncAppointments = async () => {
    setSyncing(true);
    setSyncMessage('Fetching appointments...');

    try {
      if (!appointments.length) {
        setSyncMessage('No appointments to sync.');
        setSyncing(false);
        return;
      }

      setSyncMessage('Syncing appointments with Google Calendar...');

      const promises = appointments.map((appointment) => {
        const event = {
          summary: appointment.description,
          description: appointment.description,
          start: {
            dateTime: new Date(appointment.startTime).toISOString(),
            timeZone: 'America/Los_Angeles',
          },
          end: {
            dateTime: new Date(appointment.endTime).toISOString(),
            timeZone: 'America/Los_Angeles',
          },
        };
        return createEvent(event);
      });

      const results = await Promise.all(promises);
      const successfulSyncs = results.filter((result) => result).length;

      setSyncMessage(`Sync complete: ${successfulSyncs} of ${appointments.length} appointments synced.`);
    } catch (error) {
      console.error('Error syncing appointments:', error);
      setSyncMessage('Error syncing appointments.');
    }

    setSyncing(false);
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box textAlign="center" py={10}>
      <Text fontSize="2xl" mb={6}>Google Calendar Integration</Text>
      <Text fontSize="2xl" mb={6}>Sync {appointments.length} appointments with Google Calendar</Text>

      {isLoggedIn ? (
        <VStack spacing={4}>
          <Button colorScheme="red" onClick={() => auth.signOut()}>Logout</Button>
          <Button colorScheme="teal" onClick={syncAppointments} isDisabled={syncing}>
            Sync Appointments
          </Button>
          {syncing && <Spinner />}
          {syncMessage && <Text>{syncMessage}</Text>}
        </VStack>
      ) : (
        <Button colorScheme="teal" onClick={signInWithGoogle}>
          Give PinePlanner your consent
        </Button>
      )}
    </Box>
  );
};

export default SyncWithGoogleCal;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { getUserAppointments } from '../services/api';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import FreeSideBar from '../components/common/FreeSideBar';

const MyAppointments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const userAppointments = await getUserAppointments();
        console.log('User Appointments:', userAppointments); // Log appointments data
        setAppointments(userAppointments?.data || []);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (appointments.length === 0) {
    return (
      <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"My Appointments"}/>
        <Flex align="center" justify="center" height="100vh">
          <Text>No appointment found</Text>
        </Flex>
      </Box>
    </Flex>
    );
  }

  return (
    <Flex>
      <FreeSideBar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"My Appointments"}/>
        <Box p={4} mt="80px">
          <Heading as="h2" size="lg" mb={4} color={'teal'}>
            My Appointments
          </Heading>
          <TableContainer>
            <Table variant="simple" colorScheme={'teal'} size="xs">
              <TableCaption>Appointment History</TableCaption>
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Start Time</Th>
                  <Th>End Time</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {appointments.map((appointment) => (
                  <Tr key={appointment.id}>
                    <Td>{appointment.description}</Td>
                    <Td>{new Date(appointment.startTime).toLocaleString()}</Td>
                    <Td>{new Date(appointment.endTime).toLocaleString()}</Td>
                    <Td>{appointment.status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
  );
};

export default MyAppointments;

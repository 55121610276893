import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Spinner,
  Grid,
  GridItem,
  Text,
  VStack,
  Button,
  Avatar,
  Container,
  Heading,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getProfessionals } from '../services/api';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles

const Marketplace = () => {
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await getProfessionals();
        setProfessionals(response.data);
      } catch (error) {
        setError('Error fetching professionals');
      } finally {
        setLoading(false);
      }
    };

    fetchProfessionals();
  }, []);

  const handleViewProfile = (handle) => {
    navigate(`/${handle}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500" fontSize="lg">{error}</Text>
      </Box>
    );
  }

  return (
    <Box p={5}>
      {/* Carousel Header */}
      <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false} showStatus={false}>
        <Box position="relative" maxH="300px" overflow="hidden">
          <Image src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxidXNpbmVzc3xlbnwwfHx8fDE2Mzg4NzU1MTI&ixlib=rb-1.2.1&q=80&w=1080" alt="Welcome to PinePlanner Marketplace" objectFit="cover" w="100%" h="300px" />
          <Text fontSize="xl" color="white" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="10" bg="rgba(0, 0, 0, 0.5)" p={2} borderRadius="md">
            Welcome to PinePlanner Marketplace
          </Text>
        </Box>
        <Box position="relative" maxH="300px" overflow="hidden">
          <Image src="/assets/images/dental.png" alt="Discover Professionals for Every Need" objectFit="cover" w="100%" h="300px" />
          <Text fontSize="xl" color="white" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="10" bg="rgba(0, 0, 0, 0.5)" p={2} borderRadius="md">
            Discover Professionals for Every Need
          </Text>
        </Box>
        <Box position="relative" maxH="300px" overflow="hidden">
        <Image src="/assets/images/dental.png" alt="Discover Professionals for Every Need" objectFit="cover" w="100%" h="300px" />
        <Text fontSize="xl" color="white" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="10" bg="rgba(0, 0, 0, 0.5)" p={2} borderRadius="md">
            Seamless Appointment Scheduling
          </Text>
        </Box>
      </Carousel>

      {/* Marketplace Description */}
      <Container maxW="container.xl" py={10} textAlign="center">
        <Heading as="h2" size="xl" mb={4} color="teal.500">PinePlanner Marketplace</Heading>
        <Text fontSize="lg" color="gray.600">
          Browse through our list of professionals to find the perfect match for your needs. Whether you need a business consultant, a software developer, or any other expert, PinePlanner has you covered.
        </Text>
      </Container>

      {/* Professional Listings */}
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {professionals.map((professional) => (
          <GridItem key={professional.id} w="100%" boxShadow="md" p={5} borderRadius="md" borderWidth="1px" overflow="hidden">
            <VStack spacing={4} textAlign="center">
              <Avatar
                size="2xl"
                name={`${professional.firstName} ${professional.lastName}`}
                src={professional.avatar}
              />
              <Text fontWeight="bold" fontSize="xl">{`${professional.firstName} ${professional.lastName}`}</Text>
              <Text fontSize="md" color="gray.600">{professional.profession}</Text>
              <Text fontSize="sm" color="gray.500">{professional.bio.split("\n").slice(0, 3).join(" ") + '...'}</Text>
              <Button colorScheme="teal" onClick={() => handleViewProfile(professional.handle)}>View Profile</Button>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default Marketplace;

import * as React from 'react';
import {
    ChakraProvider,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    IconButton,
    Link,
    Input,
    Text,
    Stack,
    Flex,
    Heading,
    useColorMode,
    useColorModeValue,
    useToast,
    Spinner,
    InputRightElement,
    InputGroup,
} from '@chakra-ui/react';
import { FaGoogle, FaRegUserCircle } from 'react-icons/fa';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import '../services/firebase'; // Make sure this is the correct path to your firebase.js
import { auth, logInWithEmailAndPassword, signInWithGoogle } from '../services/firebase';
import Footer from '../components/common/Footer';
import {SecondaryLogo} from '../components/common/LogoPine';

const GoogleIcon = () => <FaGoogle />;



export default function SignIn() {
    const bg = useColorModeValue('white', 'gray.900');
    const boxBg = useColorModeValue('white', 'gray.700');
    const formBg = useColorModeValue('white', 'gray.800');
    const toast = useToast();
    const navigate = useNavigate();  // useNavigate instead of useHistory
    const [show, setShow] = React.useState(false)
    const hideShowPassword = () => setShow(!show)

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleEmailSignIn = async (e) => {
        try {
            setLoading(true);
          var user=  await logInWithEmailAndPassword(email, password);
            setLoading(false);
            toast({
                title: 'Welcome '+user.displayName,
                description: "Signed In Successfully",
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
            navigate('/');

        } catch (error) {
            console.error(error);
            toast({
                title: 'Error signing in.',
                description: error.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
            setLoading(false);

        }
    };
    const handleGoogleLogin = async (e) => {
        e.preventDefault();
        try {
            const googleUser = await signInWithGoogle();
            navigate('/'); // Redirect to the dashboard after sign-in
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <ChakraProvider>
            <Flex minH="100vh" direction={{ base: 'column', md: 'row' }}>
                <Box
                    w={{ base: '100%', md: '50%' }}
                    minH={{ base: '50vh', md: '100vh' }}
                    bgImage="/assets/images/background.png"
                    bgSize="cover"
                    bgPosition="center"
                    position="relative"
                />
                <Flex
                    w={{ base: '100%', md: '50%' }}
                    minH={{ base: '50vh', md: '100vh' }}
                    align="center"
                    justify="center"
                    bg={bg}
                    px={4}
                >
                    <Box w="full" maxW="lg" bg={boxBg}  p={6} borderRadius="md">
                        <Flex justify="space-between" align="center" mb={6}>
                            <Flex align="center">
                                <SecondaryLogo/>
                            </Flex>
                        </Flex>

                        <Box as="main" bg={formBg} p={6} borderRadius="md">
                            <Stack spacing={4} mb={6}>
                                <Stack spacing={1}>
                                    <Heading as="h1" size="lg" color={'teal'}>Sign in</Heading>
                                    <Text fontSize="sm">
                                        New to PinePlanner?{' '}
                                        <Link href="/sign-up" color="teal">Sign up!</Link>
                                    </Text>
                                </Stack>
                                <Button variant="solid" colorScheme="gray" leftIcon={<GoogleIcon />} w="full" onClick={handleGoogleLogin}>
                                    Continue with Google
                                </Button>
                            </Stack>
                            <Divider />
                            <Stack spacing={4} mt={6}>
                                <form>
                                    <FormControl isRequired>
                                        <FormLabel>Email</FormLabel>
                                        <Input type="email" name="email" value={email}
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Password</FormLabel>
                                        <InputGroup>
                                        <Input  name="password" value={password} type={show ? 'text' : 'password'}
                                            onChange={(e) => setPassword(e.target.value)} required />
                                            <InputRightElement width='4.5rem'>
                                                <Button h='1.75rem' size='sm' colorScheme={'teal'} onClick={hideShowPassword}>
                                                {show ? 'Hide' : 'Show'}
                                                </Button>
                                            </InputRightElement>
                                            </InputGroup>
                                    </FormControl>
                                    <Stack spacing={4} mt={2}>
                                        <Flex justify="space-between" align="center">
                                            <Checkbox color={'teal'} name="persistent">Remember me</Checkbox>
                                            <Link color="teal" href="#replace-with-a-link">Forgot your password?</Link>
                                        </Flex>
                                        <Button colorScheme="teal" w="full" onClick={() => handleEmailSignIn(email, password)} isLoading={loading} > Sign In </Button>
                                    </Stack>
                                </form>
                            </Stack>
                        </Box>
                        <Footer />

                       
                    </Box>
                </Flex>

            </Flex>
        </ChakraProvider>
    );
}

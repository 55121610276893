import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Text,
  Textarea,
  Button,
  Spinner,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { createFeedback, getProfessionalProfile } from '../services/api'; // Assume you have an API service to fetch professional data

const RatingPage = () => {
  const { handle,appointmentID } = useParams();
  const [professional, setProfessional] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [review, setReview] = useState('');
  const [serviceDelivered, setServiceDelivered] = useState(null);
  const [submitting, setSubmitting] = useState(false); // For submit button loader
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue('white', 'gray.800');
  const boxShadow = useColorModeValue('xl', 'dark-lg');

  useEffect(() => {
    const fetchProfessionalData = async () => {
      try {
        const profile = await getProfessionalProfile(handle);
        setProfessional(profile?.data);
      } catch (error) {
        console.error('Error fetching professional data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfessionalData();
  }, [handle]);

  const handleSubmit = async () => {
    if (rating === 0 || serviceDelivered === null || review.trim() === '') {
      toast({
        title: 'Incomplete Submission',
        description: 'Please complete all fields before submitting.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setSubmitting(true); // Start the loader

    try {
      await createFeedback({
        uid: professional.uid,
        review: review.trim(),
        serviceDelivered: serviceDelivered,
        handle: professional.handle,
        rating: rating, // added rating field
        appointmentID:appointmentID
      });

      toast({
        title: 'Feedback Submitted',
        description: 'Thank you for your feedback!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate(`/${professional.handle}`);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast({
        title: 'Submission Failed',
        description: 'There was an issue submitting your feedback. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false); // Stop the loader
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!professional) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text fontSize="xl">Professional not found</Text>
      </Flex>
    );
  }

  const {
    firstName = 'No Name',
    lastName = 'No Name',
    profession = 'No Profession',
  } = professional;

  return (
    <Flex align="center" justify="center" direction="column" p={4} bg="gray.100" minH="100vh">
      <Box
        w="full"
        maxW={{ base: 'full', md: '2xl' }}
        p={6}
        borderWidth={1}
        borderRadius="lg"
        bg={bgColor}
        boxShadow={boxShadow}
        textAlign="center"
      >
        <Heading as="h2" size="lg" mb={4} color="teal.500">
          Rate Your Experience with {firstName} {lastName}
        </Heading>
        <Heading as="h2" size="lg" mb={4}>
          {profession}
        </Heading>
        <Text fontSize="lg" mb={2}>
          Was the appointment completed?
        </Text>
        <Flex justify="center" mb={4}>
          <Button
            colorScheme={serviceDelivered === true ? 'teal' : 'gray'}
            variant={serviceDelivered === true ? 'solid' : 'outline'}
            mr={2}
            onClick={() => setServiceDelivered(true)}
          >
            Yes
          </Button>
          <Button
            colorScheme={serviceDelivered === false ? 'teal' : 'gray'}
            variant={serviceDelivered === false ? 'solid' : 'outline'}
            onClick={() => setServiceDelivered(false)}
          >
            No
          </Button>
        </Flex>
        <Text fontSize="lg" mb={2}>
          Rate the service
        </Text>
        <Flex justify="center" mb={4}>
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            return (
              <FaStar
                key={index}
                size={30}
                color={starValue <= (hover || rating) ? '#FFD700' : '#e4e5e9'}
                onClick={() => setRating(starValue)}
                onMouseEnter={() => setHover(starValue)}
                onMouseLeave={() => setHover(rating)}
                style={{ cursor: 'pointer', marginRight: '8px' }}
              />
            );
          })}
        </Flex>
        <Text fontSize="lg" mb={2}>
          Write a review
        </Text>
        <Textarea
          placeholder="Tell us more about your experience"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          mb={4}
        />
        <Button
          colorScheme="teal"
          onClick={handleSubmit}
          isLoading={submitting} // Loader for submit button
          loadingText="Submitting"
        >
          Submit Feedback
        </Button>
      </Box>
    </Flex>
  );
};

export default RatingPage;

// App.js
import React from 'react';
import { Box, ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/Login';
import SignUp from './pages/Signup';
import Landing from './pages/Landing';
import ThankYouPage from './components/Appointment/thankYou';
import Schedules from './pages/Schedules';
import History from './pages/History';
import MyAppointments from './pages/MyAppointments';
import MyClient from './pages/MyClients';
import SyncWithGoogleCal from './components/SyncWithGoogleCal';
import Marketplace from './pages/MarketPlace';
import MyServices from './pages/MyServices';
import EncountersPage from './pages/Encounters';
import NotificationPage from './pages/NotificationPage';
import ErrorBoundary from './components/common/ErrorBoundary';
import { auth } from './services/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import PricingDollar from './components/Billing/pricing-dollar';
import RatingPage from './pages/RatingPage';

const PrivateRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return   <Flex align="center" justify="center" height="100vh">
    <Spinner />
  </Flex>;
  }

  return isAuthenticated ? element : <Navigate to="/sign-in" />;
};

const App = () => (
  <ChakraProvider>
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/" element={<PrivateRoute element={<Home />} />} />
            <Route path="/history" element={<PrivateRoute element={<History />} />} />
            <Route path="/schedules" element={<PrivateRoute element={<Schedules />} />} />
            <Route path="/:handle" element={<Landing />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/payment" element={<PricingDollar />} />
            <Route path="/my-appointments" element={<MyAppointments />} />
            <Route path="/my-clients" element={<MyClient />} />
            <Route path="/google-calendar-sync" element={<SyncWithGoogleCal />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/my-services" element={<MyServices />} />
            <Route path="/encounters/:appointmentId" element={<EncountersPage />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path="/acknowledgement/:handle" element={<RatingPage />} />

          </Routes>
        </ErrorBoundary>
      </Router>
    </Box>
  </ChakraProvider>
);

export default App;

// ErrorBoundary.js
import React, { Component } from 'react';
import { Box, Text, Button } from '@chakra-ui/react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log error information to an error reporting service here.
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleRetry = () => {
    // Implement retry logic or navigation to a safe state
    window.location.reload(); // Simple way to reload the app
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box p={4} bg="red.100" borderRadius="md" boxShadow="md" textAlign="center">
          <Text fontSize="xl" mb={4}>Something went wrong. Please try again later.</Text>
          <Button colorScheme="red" onClick={this.handleRetry}>Retry</Button>
        </Box>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;

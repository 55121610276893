import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
  VStack,
  HStack,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { getClients, getUserclients } from '../services/api';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';

const MyClient = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setclients] = useState([]);
  const [loading, setLoading] = useState(true);
  const boxBg = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    const fetchclients = async () => {
      try {
        const tenantClients = await getClients();
        console.log('Tenant clients:', tenantClients); // Log clients data
        setclients(tenantClients?.data || []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchclients();
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  if (clients.length === 0) {
    return (
        <Flex>
        <Sidebar isOpen={isOpen} onToggle={handleToggle} />
        <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
          <Header isOpen={isOpen} onToggle={handleToggle} title={"My Clients"}/>
          <Flex align="center" justify="center" height="100vh">
            <Text>No Clients</Text>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex>
      <Sidebar isOpen={isOpen} onToggle={handleToggle} />
      <Box flex="1" ml={isOpen ? '250px' : '60px'} transition="margin-left 0.3s">
        <Header isOpen={isOpen} onToggle={handleToggle} title={"My Clients"}/>
        <Box p={4} mt="80px">
         
          <TableContainer>
            <Table variant="simple" colorScheme={'teal'} size="xs">
              <TableCaption> My Client</TableCaption>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Phone Number</Th>
                  <Th>Created At</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clients.map((client) => (
                  <Tr key={client.id}>
                    <Td>{client.name}</Td>
                    <Td>{client.email}</Td>
                    <Td>{client.phoneNumber}</Td>

                    <Td>{client.createdAt}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Flex>
  );
};

export default MyClient;

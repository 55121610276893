import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  VStack,
  Spinner,
  Button,
  Divider,
  useColorModeValue,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from '@chakra-ui/react';
import { FaSignInAlt } from 'react-icons/fa';
import { getProfessionalProfile, getServicesByProfessionalID, logClicks, logPageViews } from '../services/api';
import BookAppointmentModal from '../components/Appointment/bookAppointmentModal';

const formatCurrency = (value, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value);
};

const Landing = () => {
  const { handle } = useParams();
  const [professional, setProfessional] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();

  const fallbackData = useMemo(() => ({
    firstName: 'No Name',
    lastName: 'No Name',
    role: 'No Plan',
    profession: "No Profession",
    bio: 'No Bio',
    email: 'No email',
    phoneNumber: 'No Phone',
  }), []);

  const bgColor = useColorModeValue('white', 'gray.800');
  const boxShadow = useColorModeValue('xl', 'dark-lg');

  useEffect(() => {
    const fetchProfessionalData = async () => {
      try {
        const profile = await getProfessionalProfile(handle);
        setProfessional(profile?.data);
         await logPageViews(profile?.data.uid);

        const services = await getServicesByProfessionalID(profile?.data.uid);
        setServices(services?.data || []);

      } catch (error) {
        console.error('Error fetching professional data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfessionalData();
  }, [handle]);

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!professional) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text fontSize="xl">Professional not found</Text>
      </Flex>
    );
  }

  const {
    firstName = fallbackData.firstName,
    lastName = fallbackData.lastName,
    profession = fallbackData.profession,
    bio = fallbackData.bio,
    photoURL = fallbackData.photoURL,
  } = professional;

  const handleOpen = async (service) => {
    setSelectedService(service);
    onOpen();
    await logClicks(professional.uid);

  };

  return (
    <Flex align="center" justify="center" direction="column" p={4} bg="gray.100" minH="100vh">
      <Box
        w="full"
        maxW={{ base: 'full', md: '4xl' }}
        p={4}
        borderWidth={1}
        borderRadius="lg"
        bg={bgColor}
        boxShadow={boxShadow}
        transition="all 0.3s"
        _hover={{ transform: 'scale(1.02)' }}
      >
        <Center mb={4} bg={'teal.50'}>
          <Avatar
            size={{ base: 'xl', md: '2xl' }}
            name={`${firstName} ${lastName}`}
            src={photoURL}
            mb={4}
            borderColor="teal.500"
            borderWidth={4}
          />
        </Center>
        <Heading
          fontSize={{ base: 'xl', md: '2xl' }}
          textAlign="center"
          mb={4}
          color="teal"
          p={2}
          borderRadius="md"
        >
          {`${firstName} ${lastName}`}
        </Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.500" textAlign="center" mb={4}>
          {profession}
        </Text>
        <Divider mb={4} />
        <VStack spacing={4} align="start">
          <Text fontSize={{ base: 'sm', md: 'md' }} mb={4}>{bio}</Text>
          <Divider mt={4} />
          <Flex direction="column" align="center" mt={4} w="full">
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" mb={4}>
              Services
            </Text>
            <Box w="full" overflowX="auto">
              <Table variant="simple" w="full" minW="sm">
                <Thead>
                  <Tr>
                    <Th>Service Name</Th>
                    <Th>Description</Th>
                    <Th isNumeric>Price</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {services.map(service => (
                    <Tr key={service.id}>
                      <Td>{service.name}</Td>
                      <Td>{service.description}</Td>
                      <Td isNumeric>{formatCurrency(service.price, service.currency)}</Td>
                      <Td>
                        <Button variant={'outline'} colorScheme="teal" onClick={() => handleOpen(service)}>
                          Book
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} align="center" mt={4} justify="space-around" w="full">
            <Box mt={{ base: 4, sm: 0 }}>
              <Button colorScheme="teal" leftIcon={<FaSignInAlt />} onClick={() => navigate("/")}>
                Monetize your time and services
              </Button>
            </Box>
          </Flex>
        </VStack>
      </Box>
      {isOpen && selectedService && (
        <BookAppointmentModal
          isOpen={isOpen}
          onClose={onClose}
          professionalId={professional.uid}
          selectedService={selectedService}
        />
      )}
    </Flex>
  );
};

export default Landing;
